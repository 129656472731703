<div class="navbar-container">
  <nav class="navbar navbar-expand navbar-dark bg-dark justify-content-between">
    <a class="navbar-brand" href="#">{{'application.esprio' | translate}}</a>
  
    <span class="text-white">{{'application.global' | translate}}</span>

    <div class="d-flex">
      <ul class="navbar-nav me-auto">
        <ng-container *ngIf="isLoggedIn$ | async">
          <li class="nav-item d-flex align-items-center">
            <span
              class="material-icons nav-link account-item"
              (click)="handleProfileClick()"
              >
              account_circle
            </span>
          </li>
        </ng-container>
  
        <ng-container *ngIf="!(isLoggedIn$ | async)">
          <li class="nav-item">
            <a class="nav-link" (click)="handleLoginClick()">{{'auth.login' | translate}}</a>
          </li>
        </ng-container>
      </ul>
    </div>
  
  </nav>
  <div class="color-gradient"></div>
</div>
