import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function numberValidator(min: number | undefined, max: number | undefined): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value as number;
    if (!isValidNumber(value)) {
      return null;
    }

    if (value !== Math.floor(value)) {
      return {
        nonInteger: true,
      };
    }

    if (isValidNumber(min) && value < min!) {
      return {
        min: true,
      };
    }

    if (isValidNumber(max) && value > max!) {
      return {
        max: true,
      };
    }

    return null;
  };
}

function isValidNumber(number: number | undefined): boolean {
  return Boolean(number) || number === 0;
}
