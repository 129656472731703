import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AuthSelectors, AuthUINavigationActions, BaseAppState, ProfilePageComponent } from 'shared';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent {

  isLoggedIn$ = this.store.pipe(select(AuthSelectors.isLoggedIn));

  constructor(
    private store: Store<BaseAppState>,
    private modalService: BsModalService,
  ) { }

  handleProfileClick() {
    this.modalService.show(ProfilePageComponent);
  }

  handleSignUpClick() {
    this.store.dispatch(AuthUINavigationActions.goToSignUp());
  }

  handleLoginClick() {
    this.store.dispatch(AuthUINavigationActions.goToLogin());
  }

}
