import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { filter, take, tap } from 'rxjs/operators';
import { BaseAppState, StudyCenterApiActions, StudyCenterSelectors, UsersApiActions, UsersSelectors } from 'shared';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent implements OnInit {

  readonly processKeys = [
    UsersApiActions.getUsers.type,
    UsersApiActions.addUser.type,
    UsersApiActions.editUser.type,
    StudyCenterApiActions.getStudyCenters.type,
  ];

  areUsersFetched$ = this.store.pipe(select(UsersSelectors.areUsersFetched));
  users$ = this.store.pipe(select(UsersSelectors.getUsers));
  areStudyCentersFetched$ = this.store.pipe(select(StudyCenterSelectors.areStudyCentersFetched));
  studyCenters$ = this.store.pipe(select(StudyCenterSelectors.getStudyCenters));

  constructor(private store: Store<BaseAppState>) { }

  ngOnInit(): void {
    this.fetchData();
  }

  private fetchData() {
    this.areUsersFetched$.pipe(
      filter((fetched: boolean) => !fetched),
      take(1),
      tap(() => this.store.dispatch(UsersApiActions.getUsers())),
    ).subscribe();

    this.areStudyCentersFetched$.pipe(
      filter((fetched: boolean) => !fetched),
      take(1),
      tap(() => this.store.dispatch(StudyCenterApiActions.getStudyCenters())),
    ).subscribe();
  }

}
