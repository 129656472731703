import { Routes } from '@angular/router';
import { LoggedInGuard, UserRole, UserRoleGuard } from 'shared';

import { StudyCentersListComponent } from '../../study-centers/components/study-centers-list/study-centers-list.component';
import {
  ParticipantDetailsComponent,
} from '../../users-global-management/components/participant-details/participant-details.component';
import { UserListComponent } from '../../users-global-management/components/users-list/user-list.component';
import {
  GLOBAL_MANAGEMENT_PATH_ELEMENTS,
  GLOBAL_MANAGEMENT_PATHS,
} from './definitions/global-management-navigation.definitions';

export const routes: Routes = [
  {
    path: GLOBAL_MANAGEMENT_PATH_ELEMENTS.studyCentersRoot,
    canActivate: [LoggedInGuard, UserRoleGuard],
    data: {
      roles: [UserRole.GlobalManagement],
    },
    children: [
      {
        path: GLOBAL_MANAGEMENT_PATH_ELEMENTS.studyCenters.listElement,
        pathMatch: 'full',
        component: StudyCentersListComponent,
      },
      {
        path: '**',
        redirectTo: GLOBAL_MANAGEMENT_PATHS.studyCenters.list,
      },
    ],
  },
  {
    path: GLOBAL_MANAGEMENT_PATH_ELEMENTS.usersRoot,
    canActivate: [LoggedInGuard, UserRoleGuard],
    data: {
      roles: [UserRole.GlobalManagement],
    },
    children: [
      {
        path: GLOBAL_MANAGEMENT_PATH_ELEMENTS.users.listElement,
        pathMatch: 'full',
        component: UserListComponent,
      },
      {
        path: '**',
        redirectTo: GLOBAL_MANAGEMENT_PATHS.users.list,
      },
    ],
  },
  {
    path: GLOBAL_MANAGEMENT_PATH_ELEMENTS.participantDetailsRoot,
    canActivate: [LoggedInGuard, UserRoleGuard],
    data: {
      roles: [UserRole.GlobalManagement],
    },
    children: [
      {
        path: GLOBAL_MANAGEMENT_PATH_ELEMENTS.participantDetails.participantDetailsElement,
        pathMatch: 'full',
        component: ParticipantDetailsComponent,
      },
    ],
  },
  {
    path: '**',
    redirectTo: GLOBAL_MANAGEMENT_PATHS.studyCenters.list,
  },
];
