import { AbstractControl, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';

const DATE_PATTERN = new RegExp('^(3[01]|[12][0-9]|0?[1-9])\\.(1[012]|0?[1-9])\\.((?:19|20)\\d{2})$');
const DATE_FORMAT = 'DD.MM.YYYY';

const SIMPLE_DATE_PATTERN = new RegExp('^(1[012]|0?[1-9])\\.((?:19|20)\\d{2})$');

export function dateStringValidator(): ValidatorFn {
  return (element: AbstractControl) => {
    const value: string | undefined = element.value;
    if (!value) {
      return null;
    }

    if (!DATE_PATTERN.test(element.value)) {
      return {
        pattern: true,
      };
    }

    const selectedDate = moment(element.value, DATE_FORMAT);
    if (!selectedDate) {
      return {
        pattern: true,
      };
    }

    const now = new Date();
    if (selectedDate.isAfter(now, 'date')) {
      return {
        future: true,
      };
    }

    return null;
  };
}

export function simpleDateStringValidator(): ValidatorFn {
  return (element: AbstractControl) => {
    const value: string | undefined = element.value;
    if (!value) {
      return null;
    }

    if (!SIMPLE_DATE_PATTERN.test(element.value)) {
      return {
        pattern: true,
      };
    }

    return null;
  };
}
