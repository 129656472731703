import { props } from '@ngrx/store';
import { LoginRequest, SignUpRequest } from 'projects/shared/src/lib/models';

import { createAsyncAction } from '../../async-state/utils/async-state-action.utils';

export enum SignUpActionTypes {
  SignUp = '[SignUp] SignUp',
  SignUpSuccess = '[SignUp API] SignUp Success',
  SignUpFail = '[SignUp API] SignUp Failure',
}

export const signUp = createAsyncAction(
  SignUpActionTypes.SignUp,
  {
    asyncKey: SignUpActionTypes.SignUp,
    asyncState: 'start',
  },
  props<{ payload: SignUpRequest }>(),
);

export const signUpSuccess = createAsyncAction(
  SignUpActionTypes.SignUpSuccess,
  {
    asyncKey: SignUpActionTypes.SignUp,
    asyncState: 'success',
  },
  props<{ payload: LoginRequest }>(),
);

export const signUpFail = createAsyncAction(
  SignUpActionTypes.SignUpFail,
  {
    asyncKey: SignUpActionTypes.SignUp,
    asyncState: 'fail',
  },
);

