import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { loginSuccess } from '../../core/auth/actions/auth.actions';
import * as UserAPIActions from '../actions/users-api.actions';
import { BaseUser } from '../models';

export interface UsersState extends EntityState<BaseUser> {
  isFetched: boolean;
  partiallyFetched: boolean;
}

export const usersAdapter: EntityAdapter<BaseUser> = createEntityAdapter<BaseUser>();

const initialState: UsersState = usersAdapter.getInitialState({
  isFetched: false,
  partiallyFetched: false,
});

const reducer = createReducer(
  initialState,
  on(
    loginSuccess,
    (state: UsersState, { loginResponse }) => usersAdapter.upsertOne(loginResponse.user, {
      ...state,
    }),
  ),
  on(
    UserAPIActions.getUsersSuccess,
    (state: UsersState, { users }) => usersAdapter.upsertMany(users, {
      ...state,
      isFetched: true,
      partiallyFetched: true,
    }),
  ),
  on(
    UserAPIActions.getUsersForStudyCenterSuccess,
    (state: UsersState, { users }) => usersAdapter.upsertMany(users, {
      ...state,
      partiallyFetched: true,
    }),
  ),
  on(
    UserAPIActions.getUserSuccess,
    UserAPIActions.addUserSuccess,
    UserAPIActions.editUserSuccess,
    (state: UsersState, { user }) => usersAdapter.upsertOne(user, { ...state }),
  ),
);

export function usersReducer(state = initialState, action: Action): UsersState {
  return reducer(state, action);
}
