import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { SubmissionsNavigationActions } from 'shared';

import * as GlobalManagementNavigationActions from '../actions/global-management-navigation.actions';
import { GLOBAL_MANAGEMENT_PATHS } from '../definitions/global-management-navigation.definitions';

@Injectable()
export class GlobalManagementNavigationEffects {

  goToStudyCentersList$ = createEffect(() => this.actions$.pipe(
    ofType(GlobalManagementNavigationActions.goToStudyCenterList),
    tap(() => this.router.navigate([GLOBAL_MANAGEMENT_PATHS.studyCenters.list]))),
    { dispatch: false },
  );

  goToUsersList$ = createEffect(() => this.actions$.pipe(
    ofType(GlobalManagementNavigationActions.goToUserList),
    tap(() => this.router.navigate([GLOBAL_MANAGEMENT_PATHS.users.list]))),
    { dispatch: false },
  );

  goToParticipantDetails$ = createEffect(() => this.actions$.pipe(
    ofType(SubmissionsNavigationActions.goToParticipantDetails),
    tap((action) => this.router.navigate([GLOBAL_MANAGEMENT_PATHS.participantDetails.patient(action.participantId)]))),
   { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private router: Router,
  ) { }
}
