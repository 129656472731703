import { props } from '@ngrx/store';

import { createAsyncAction } from '../../core/async-state/utils';
import { BaseUser } from '../models';
import { AddUserRequest } from '../models/add-user-request.model';
import { EditUserRequest } from '../models/edit-user-request.model';

export enum UserAPIActionTypes {
  GetUsers = '[User API] Get users',
  GetUsersSuccess = '[User API] Get users success',
  GetUsersFail = '[User API] Get users fail',

  GetUser = '[User API] Get user',
  GetUserSuccess = '[User API] Get user success',
  GetUserFail = '[User API] Get user fail',

  GetUsersForStudyCenter = '[User API] Get users for study center',
  GetUsersForStudyCenterSuccess = '[User API] Get users for study center success',
  GetUsersForStudyCenterFail = '[User API] Get users for study center fail',

  AddUser = '[User API] Add user',
  AddUserSuccess = '[User API] Add user success',
  AddUserFail = '[User API] Add user fail',

  EditUser = '[User API] Edit user',
  EditUserSuccess = '[User API] Edit user success',
  EditUserFail = '[User API] Edit user fail',
}

export const getUsers = createAsyncAction(
  UserAPIActionTypes.GetUsers,
  {
    asyncKey: UserAPIActionTypes.GetUsers,
    asyncState: 'start',
  },
);

export const getUsersSuccess = createAsyncAction(
  UserAPIActionTypes.GetUsersSuccess,
  {
    asyncKey: UserAPIActionTypes.GetUsers,
    asyncState: 'success',
  },
  props<{ users: BaseUser[] }>(),
);

export const getUsersFail = createAsyncAction(
  UserAPIActionTypes.GetUsersFail,
  {
    asyncKey: UserAPIActionTypes.GetUsers,
    asyncState: 'fail',
  },
);

export const getUser = createAsyncAction(
  UserAPIActionTypes.GetUser,
  {
    asyncKey: UserAPIActionTypes.GetUser,
    asyncState: 'start',
  },
  props<{ userId: string }>(),
);

export const getUserSuccess = createAsyncAction(
  UserAPIActionTypes.GetUserSuccess,
  {
    asyncKey: UserAPIActionTypes.GetUser,
    asyncState: 'success',
  },
  props<{ user: BaseUser }>(),
);

export const getUserFail = createAsyncAction(
  UserAPIActionTypes.GetUserFail,
  {
    asyncKey: UserAPIActionTypes.GetUser,
    asyncState: 'fail',
  },
);

export const getUsersForStudyCenter = createAsyncAction(
  UserAPIActionTypes.GetUsersForStudyCenter,
  {
    asyncKey: UserAPIActionTypes.GetUsersForStudyCenter,
    asyncState: 'start',
  },
  props<{ studyCenterId: string }>(),
);

export const getUsersForStudyCenterSuccess = createAsyncAction(
  UserAPIActionTypes.GetUsersForStudyCenterSuccess,
  {
    asyncKey: UserAPIActionTypes.GetUsersForStudyCenter,
    asyncState: 'success',
  },
  props<{ users: BaseUser[] }>(),
);

export const getUsersForStudyCenterFail = createAsyncAction(
  UserAPIActionTypes.GetUsersForStudyCenterFail,
  {
    asyncKey: UserAPIActionTypes.GetUsersForStudyCenter,
    asyncState: 'fail',
  },
);

export const addUser = createAsyncAction(
  UserAPIActionTypes.AddUser,
  {
    asyncKey: UserAPIActionTypes.AddUser,
    asyncState: 'start',
  },
  props<{ request: AddUserRequest }>(),
);

export const addUserSuccess = createAsyncAction(
  UserAPIActionTypes.AddUserSuccess,
  {
    asyncKey: UserAPIActionTypes.AddUser,
    asyncState: 'success',
  },
  props<{ user: BaseUser }>(),
);

export const addUserFail = createAsyncAction(
  UserAPIActionTypes.AddUserFail,
  {
    asyncKey: UserAPIActionTypes.AddUser,
    asyncState: 'fail',
  },
);

export const editUser = createAsyncAction(
  UserAPIActionTypes.EditUser,
  {
    asyncKey: UserAPIActionTypes.EditUser,
    asyncState: 'start',
  },
  props<{ userId: string, request: EditUserRequest }>(),
);

export const editUserSuccess = createAsyncAction(
  UserAPIActionTypes.EditUserSuccess,
  {
    asyncKey: UserAPIActionTypes.EditUser,
    asyncState: 'success',
  },
  props<{ user: BaseUser }>(),
);

export const editUserFail = createAsyncAction(
  UserAPIActionTypes.EditUserFail,
  {
    asyncKey: UserAPIActionTypes.EditUser,
    asyncState: 'fail',
  },
);
