import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BsModalService } from 'ngx-bootstrap/modal';
import { filter, take, tap } from 'rxjs/operators';
import { BaseAppState, StudyCenter, StudyCenterApiActions, StudyCenterSelectors } from 'shared';

import { AddStudyCenterModalComponent } from '../modal-components/add-study-center-modal/add-study-center-modal.component';
import { EditStudyCenterModalComponent } from '../modal-components/edit-study-center-modal/edit-study-center-modal.component';

@Component({
  selector: 'app-study-centers-list',
  templateUrl: './study-centers-list.component.html',
  styleUrls: ['./study-centers-list.component.scss'],
})
export class StudyCentersListComponent implements OnInit {

  readonly processKeys = [
    StudyCenterApiActions.getStudyCenters.type,
    StudyCenterApiActions.editStudyCenter.type,
    StudyCenterApiActions.addStudyCenter.type,
  ];

  studyCentersFetched$ = this.store.pipe(select(StudyCenterSelectors.areStudyCentersFetched));
  studyCenters$ = this.store.pipe(select(StudyCenterSelectors.getStudyCenters));

  constructor(
    private store: Store<BaseAppState>,
    private modalService: BsModalService,
  ) { }

  ngOnInit(): void {
    this.fetchStudyCenters();
  }

  public handleEditStudyCenterClick(studyCenter: StudyCenter) {
    this.modalService.show(EditStudyCenterModalComponent, {
      initialState: {
        studyCenter: studyCenter,
      },
    });
  }

  public handleAddStudyCenterClick() {
    this.modalService.show(AddStudyCenterModalComponent);
  }

  private fetchStudyCenters() {
    this.studyCentersFetched$.pipe(
      filter(((studyCentersFetched: boolean) => !Boolean(studyCentersFetched))),
      take(1),
      tap(() => this.store.dispatch(StudyCenterApiActions.getStudyCenters())),
    ).subscribe();
  }

}
