import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';

import { USERS_STATE_KEY } from '../definitions/users.definitions';
import { usersAdapter, UsersState } from '../reducers/users-reducer';

export const getUsersState = createFeatureSelector<UsersState>(USERS_STATE_KEY);

export const {
  selectIds: getUserIds,
  selectEntities: getUsersMap,
  selectAll: getUsers,
} = usersAdapter.getSelectors(getUsersState);

export const areUsersFetched: MemoizedSelector<object, boolean> = createSelector(
  getUsersState,
  (state: UsersState) => state.isFetched,
);

export const areUsersPartiallyFetched: MemoizedSelector<object, boolean> = createSelector(
  getUsersState,
  (state: UsersState) => state.partiallyFetched || state.isFetched,
);

