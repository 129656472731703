<form [formGroup]="editStudyCenterForm" (ngSubmit)="handleEditStudyCenterClick()" #ngForm="ngForm" novalidate>
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">{{'study-centers.edit' | translate}}</h5>
    <button type="button" class="close" (click)="handleCloseClick()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <label for="studyCenterName">{{'study-centers.single' | translate}}:</label>
    <input #studyCenterName formControlName="studyCenterName" type="text" class="form-control" placeholder="{{'study-centers.name-required' | translate}}"/>
    <textarea #studyCenterDescription formControlName="studyCenterDescription" class="form-control mt-2" rows="5" placeholder="{{'study-centers.description' | translate}}"></textarea>
  </div>
  <div class="modal-footer justify-content-between">
    <div>
      <!-- <button type="button" class="btn btn-danger" (click)="handleCloseClick()">Löschen</button> -->
    </div>
    <div>
      <button type="button" class="btn btn-secondary mr-2" (click)="handleCloseClick()">{{'application.cancel' | translate}}</button>
      <button type="submit" class="btn btn-primary">{{'application.save' | translate}}</button>
    </div>
  </div>
</form>