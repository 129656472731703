import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AsyncStateOverlayModule, SharedPipesModule, SubmissionsModule, UsersModule } from 'shared';

import { ParticipantDetailsComponent } from './components/participant-details/participant-details.component';
import { UserListComponent } from './components/users-list/user-list.component';

@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AsyncStateOverlayModule,
    SharedPipesModule,
    UsersModule.forRoot(),
    SubmissionsModule.forRoot(),
  ],
  declarations: [
    UserListComponent,
    ParticipantDetailsComponent,
  ],
})
export class UsersGlobalManagementModule { }
