import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RouterReducerState } from '@ngrx/router-store';

import { RouterStateUrl } from '../reducers/custom-router-state-serializer';
import { ROUTER_STATE_KEY } from '../definitions/router.definitions';

export const selectRouterState = createFeatureSelector<RouterReducerState<RouterStateUrl>>(ROUTER_STATE_KEY);

export const getRouterState = createSelector(
  selectRouterState,
  (routerState) => routerState && routerState.state ? routerState.state || {} : {},
);

export const getUrl = createSelector(
  getRouterState,
  (routerState: RouterStateUrl) => routerState ? routerState.url || null : null,
);

export const getParams = createSelector(
  getRouterState,
  (routerState: RouterStateUrl) => routerState ? routerState.params || {} : {},
);

export const getQueryParams = createSelector(
  getRouterState,
  (routerState: RouterStateUrl) => routerState ? routerState.queryParams || {} : {},
);

export const getLastUrlSegment = createSelector(
  getUrl,
  (url) => !url ? '' : url.split('/').slice(-1)[0],
);

export const getFirstUrlSegment = createSelector(
  getUrl,
  (url) => !url ? '' : url.split('/')[1],
);
