import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BaseAppState } from 'projects/shared/src/lib/core/store/reducers';
import { EditStudyCenterRequest, StudyCenter } from 'projects/shared/src/lib/study-center/models';
import { StudyCenterApiActions } from 'shared';

@Component({
  selector: 'app-edit-study-center-modal',
  templateUrl: './edit-study-center-modal.component.html',
  styleUrls: ['./edit-study-center-modal.component.scss'],
})
export class EditStudyCenterModalComponent implements OnInit {

  studyCenter: StudyCenter;
  editStudyCenterForm: FormGroup;

  constructor(
    private store: Store<BaseAppState>,
    private modalRef: BsModalRef,
  ) { }

  ngOnInit() {
    this.initializeForm();
  }

  public handleEditStudyCenterClick() {
    if (this.editStudyCenterForm.invalid) {
      return;
    }

    const request: EditStudyCenterRequest = {
      name: this.editStudyCenterForm.controls['studyCenterName'].value,
      description: this.editStudyCenterForm.controls['studyCenterDescription'].value,
    };

    this.store.dispatch(StudyCenterApiActions.editStudyCenter({ studyCenterId: this.studyCenter.id, request: request }));
    this.close();
  }

  public handleCloseClick() {
    this.close();
  }

  private initializeForm() {
    this.editStudyCenterForm = new FormGroup({
      studyCenterName: new FormControl(this.studyCenter.name, Validators.required),
      studyCenterDescription: new FormControl(this.studyCenter.description),
    });
  }

  private close() {
    this.modalRef.hide();
  }

}
