import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AddStudyCenterRequest, BaseAppState, StudyCenterApiActions } from 'shared';

@Component({
  selector: 'app-add-study-center-modal',
  templateUrl: './add-study-center-modal.component.html',
  styleUrls: ['./add-study-center-modal.component.scss'],
})
export class AddStudyCenterModalComponent implements OnInit {

  addStudyCenterForm: FormGroup;

  constructor(
    private store: Store<BaseAppState>,
    private modalRef: BsModalRef,
  ) { }

  ngOnInit() {
    this.initializeForm();
  }

  public handleAddStudyCenterClick() {
    if (this.addStudyCenterForm.invalid) {
      return;
    }

    const request: AddStudyCenterRequest = {
      name: this.addStudyCenterForm.controls['studyCenterName'].value,
      description: this.addStudyCenterForm.controls['studyCenterDescription'].value,
    };

    this.store.dispatch(StudyCenterApiActions.addStudyCenter({ request: request }));

    if (!this.addStudyCenterForm.controls['addOtherStudyCenter'].value) {
      this.close();
    } else {
      this.initializeForm();
    }
  }

  public handleCloseClick() {
    this.close();
  }

  private initializeForm() {
    this.addStudyCenterForm = new FormGroup({
      studyCenterName: new FormControl('', Validators.required),
      studyCenterDescription: new FormControl(''),
      addOtherStudyCenter: new FormControl(this.addStudyCenterForm ? this.addStudyCenterForm.controls['addOtherStudyCenter'].value : false),
    });
  }

  private close() {
    this.modalRef.hide();
  }

}
