export const AUTH_UI_PATH_ELEMENTS = {
  rootElement: 'auth',
  root: {
    loginElement: 'login',
    signUpElement: 'sign-up',
    passwordResetElement: 'password-reset',
    passwordReset: {
      requestElement: 'request',
      resetElement: 'reset',
    },
    termsOfServiceElement: 'terms-of-service',
    editPasswordElement: 'edit-password',
    accessDeniedElement: 'access-denied',
    emailConfirmationElement: 'email-confirmation',
    settingsElement: 'settings',
  },
};

export const AUTH_UI_PATHS = {
  root: {
    login: `/${AUTH_UI_PATH_ELEMENTS.rootElement}/${AUTH_UI_PATH_ELEMENTS.root.loginElement}`,
    signUp: `/${AUTH_UI_PATH_ELEMENTS.rootElement}/${AUTH_UI_PATH_ELEMENTS.root.signUpElement}`,
    passwordReset: {
      request: `/${AUTH_UI_PATH_ELEMENTS.rootElement}/${AUTH_UI_PATH_ELEMENTS.root.passwordResetElement}/${AUTH_UI_PATH_ELEMENTS.root.passwordReset.requestElement}`,
      reset: `/${AUTH_UI_PATH_ELEMENTS.rootElement}/${AUTH_UI_PATH_ELEMENTS.root.passwordResetElement}/${AUTH_UI_PATH_ELEMENTS.root.passwordReset.resetElement}`,
    },
    termsOfService: `/${AUTH_UI_PATH_ELEMENTS.rootElement}/${AUTH_UI_PATH_ELEMENTS.root.termsOfServiceElement}`,
    editPassword: `/${AUTH_UI_PATH_ELEMENTS.rootElement}/${AUTH_UI_PATH_ELEMENTS.root.editPasswordElement}`,
    accessDenied: `/${AUTH_UI_PATH_ELEMENTS.rootElement}/${AUTH_UI_PATH_ELEMENTS.root.accessDeniedElement}`,
    emailConfirmation: `/${AUTH_UI_PATH_ELEMENTS.rootElement}/${AUTH_UI_PATH_ELEMENTS.root.emailConfirmationElement}`,
    settings: `/${AUTH_UI_PATH_ELEMENTS.rootElement}/${AUTH_UI_PATH_ELEMENTS.root.settingsElement}`,
  },
};
