import { createAction } from '@ngrx/store';

export enum AuthUINavigationActionTypes {
  GoToLogin = '[Router] Navigate to login',
  GoToSignUp = '[Router] Navigate to sign up',
  GoToRequestPasswordReset = '[Router] Navigate to request password reset',
  GoToSignUpSuccess = '[Router] Navigate to sign up success',
  GoToTermsOfService = '[Router] Navigate to terms of service',
  GoToEditPassword = '[Router] Navigate to edit password',
  GoToSettings = '[Router] Navigate to user settings',
}

export const goToLogin = createAction(AuthUINavigationActionTypes.GoToLogin);
export const goToSignUp = createAction(AuthUINavigationActionTypes.GoToSignUp);
export const goToSignUpSuccess = createAction(AuthUINavigationActionTypes.GoToSignUpSuccess);
export const goToRequestPasswordReset = createAction(AuthUINavigationActionTypes.GoToRequestPasswordReset);
export const goToTermsOfService = createAction(AuthUINavigationActionTypes.GoToTermsOfService);
export const goToEditPassword = createAction(AuthUINavigationActionTypes.GoToEditPassword);
export const goToSettings = createAction(AuthUINavigationActionTypes.GoToSettings);
