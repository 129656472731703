import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';

import { GlobalManagementNavigationEffects } from './effects/global-management-navigation.effects';
import { routes } from './global-management.routes';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    EffectsModule.forFeature([
      GlobalManagementNavigationEffects,
    ]),
  ],
})
export class GlobalManagementRouterModule {}
