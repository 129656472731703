import { createAction, props } from '@ngrx/store';
import { LoginRequest, LoginResponse } from 'projects/shared/src/lib/models';

import { createAsyncAction } from '../../async-state/utils/async-state-action.utils';

export enum AuthActionTypes {
  Login = '[Auth] Login',
  LoginSuccess = '[Auth API] Login Success',
  LoginFail = '[Auth API] Login Failure',
  SessionExpired = '[Auth] Session expired',
  Logout = '[Auth API] Logout',
  LogoutSuccess = '[Auth API] Logout Success',
  LogoutFail = '[Auth API] Logout Fail',
  EmailConfirmation = '[Auth API] Email Confirmation',
  EmailConfirmationSuccess = '[Auth API] Email Confirmation Success',
  EmailConfirmationFail = '[Auth API] Email Confirmation Fail',
}

export const login = createAsyncAction(
  AuthActionTypes.Login,
  {
    asyncKey: AuthActionTypes.Login,
    asyncState: 'start',
  },
  props<{ payload: LoginRequest }>(),
);

export const loginSuccess = createAsyncAction(
  AuthActionTypes.LoginSuccess,
  {
    asyncKey: AuthActionTypes.Login,
    asyncState: 'success',
  },
  props<{ loginResponse: LoginResponse }>(),
);

export const loginFail = createAsyncAction(
  AuthActionTypes.LoginFail,
  {
    asyncKey: AuthActionTypes.Login,
    asyncState: 'fail',
  },
);

export const sessionExpired = createAction(AuthActionTypes.SessionExpired);

export const logout = createAsyncAction(
  AuthActionTypes.Logout,
  {
    asyncKey: AuthActionTypes.Logout,
    asyncState: 'start',
  },
  props<{ token: string }>(),
);

export const logoutSuccess = createAsyncAction(
  AuthActionTypes.LogoutSuccess,
  {
    asyncKey: AuthActionTypes.LogoutSuccess,
    asyncState: 'success',
  },
);

export const logoutFail = createAsyncAction(
  AuthActionTypes.LogoutFail,
  {
    asyncKey: AuthActionTypes.Logout,
    asyncState: 'fail',
  },
);

export const emailConfirmation = createAsyncAction(
  AuthActionTypes.EmailConfirmation,
  {
    asyncKey: AuthActionTypes.EmailConfirmation,
    asyncState: 'start',
  },
  props<{ userId: string | undefined }>(),
);

export const emailConfirmationSuccess = createAsyncAction(
  AuthActionTypes.EmailConfirmationSuccess,
  {
    asyncKey: AuthActionTypes.EmailConfirmation,
    asyncState: 'success',
  },
  props<{ userId: string }>(),
);

export const emailConfirmationFail = createAsyncAction(
  AuthActionTypes.EmailConfirmationFail,
  {
    asyncKey: AuthActionTypes.EmailConfirmation,
    asyncState: 'fail',
  },
);
