import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BaseAppState, RouterSelectors } from 'shared';

import * as GlobalManagementNavigationActions from '../../../../router/actions/global-management-navigation.actions';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent {

  firstUrlSegment$ = this.store.pipe(select(RouterSelectors.getFirstUrlSegment));

  constructor(
    private store: Store<BaseAppState>,
    private translateService: TranslateService,
  ) { }

  handleSetLanguageClick(lang: string) {
    this.translateService.use(lang);
  }

  handleStudyCentersClick() {
    this.store.dispatch(GlobalManagementNavigationActions.goToStudyCenterList());
  }

  handleUsersClick() {
    this.store.dispatch(GlobalManagementNavigationActions.goToUserList());
  }

}
