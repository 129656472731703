import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { API_BASE, AuthUIModule, CoreModule, StudyCenterModule, SubmissionsModule, UsersModule } from 'shared';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { routes } from './app.routes';
import { GlobalManagementNavBarModule } from './core/nav-bar/global-management-nav-bar.module';
import { GlobalManagementRouterModule } from './core/router/global-management-router.module';
import { StudyCentersModule } from './study-centers/study-centers-module';
import { UsersGlobalManagementModule } from './users-global-management/users-global-management.module';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    CoreModule.forRoot(),
    StudyCenterModule.forRoot(),
    UsersModule.forRoot(),
    SubmissionsModule.forRoot(),
    RouterModule.forRoot(routes, {
      enableTracing: false,
      scrollPositionRestoration: 'top',
    }),
    AuthUIModule,
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    GlobalManagementRouterModule,
    GlobalManagementNavBarModule,
    StudyCentersModule,
    UsersGlobalManagementModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    { provide: API_BASE, useValue: environment.apiBaseUrl },
  ],
  bootstrap: [
    AppComponent,
  ],
})
export class AppModule { }

function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
