import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';

import { Questionnaire } from '../../models';
import { SURVEYS_STATE_KEY } from '../definitions/survey.definitions';
import { SurveysState } from '../reducers/surveys.reducer';

export const getSurveysState = createFeatureSelector<SurveysState>(SURVEYS_STATE_KEY);

export const getActiveQuestionnaire: MemoizedSelector<object, Questionnaire | undefined> = createSelector(
  getSurveysState,
  (state: SurveysState) => state.activeQuestionnaire,
);
