import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AsyncStateOverlayModule } from 'shared';

import {
  AddStudyCenterModalComponent,
} from './components/modal-components/add-study-center-modal/add-study-center-modal.component';
import {
  EditStudyCenterModalComponent,
} from './components/modal-components/edit-study-center-modal/edit-study-center-modal.component';
import { StudyCentersListComponent } from './components/study-centers-list/study-centers-list.component';

@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AsyncStateOverlayModule,
    TooltipModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  declarations: [
    StudyCentersListComponent,
    AddStudyCenterModalComponent,
    EditStudyCenterModalComponent,
  ],
})
export class StudyCentersModule {}

function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
