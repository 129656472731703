import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { BaseUser, Participant, UserRole } from '../../../users/models';
import { getUsersMap } from '../../../users/selectors/users-selectors';
import { AUTH_STATE_KEY } from '../definitions/auth.definitions';
import { AuthState, AuthStatus } from '../reducers/auth.reducer';
import { decodeToken, getEmailFromToken } from '../utilities/auth-utilities';

export const getAuthState = createFeatureSelector<AuthState>(AUTH_STATE_KEY);

export const getAuthStatus = createSelector(
  getAuthState,
  (state) => state.status,
);

export const isLoggedIn = createSelector(
  getAuthStatus,
  (state) => Boolean(state.token),
);

export const getToken = createSelector(
  getAuthStatus,
  (state) => state.token,
);

export const getDecodedToken = createSelector(
  getToken,
  (token) => (token ? decodeToken(token) : null),
);

export const getEmailFromAuthToken = createSelector(
  getDecodedToken,
  (token) => getEmailFromToken(token),
);

export const getUserRole = createSelector(
  getAuthStatus,
  (state: AuthStatus) => state.userRole,
);

export const getLoggedInUserId = createSelector(
  getAuthStatus,
  (state: AuthStatus) => state.userId,
);

export const getLoggedInStudyCenterId = createSelector(
  getAuthStatus,
  getUsersMap,
  (state: AuthStatus, users: Dictionary<BaseUser>) => state.userId && users[state.userId]?.studyCenterId,
);

export const getLoggedInUser = createSelector(
  getAuthStatus,
  getUsersMap,
  (state: AuthStatus, users: Dictionary<BaseUser>) => state.userId && users[state.userId],
);

export const getLoggedInUserAccountStatus = createSelector(
  getAuthStatus,
  getUsersMap,
  (state: AuthStatus, users: Dictionary<BaseUser>) => {
    return state.userId && users[state.userId]?.userRole === UserRole.Participant ?
      (users[state.userId] as Participant).accountStatus
      : undefined;
  },
);
