import { FormGroup, ValidationErrors } from '@angular/forms';

import { UserRole } from '../users/models';

export function userStudyCenterValidator(formGroup: FormGroup) {
  const userRoleForm = formGroup.controls['userRole'];
  const studyCenterIdForm = formGroup.controls['studyCenterId'];

  let nextErrors: ValidationErrors | null = {
    ...(studyCenterIdForm.errors || {}),
  };

  if (userRoleForm.value !== UserRole.GlobalManagement && studyCenterIdForm.value === '') {
    nextErrors.missingStudyCenter = true;
  } else {
    delete nextErrors.missingStudyCenter;
  }

  if (Object.keys(nextErrors).length === 0) {
    nextErrors = null;
  }

  studyCenterIdForm.setErrors(nextErrors);
  return null;
}
