<div class="container">
  <div class="d-flex justify-content-between pt-4">
    <h1>{{'study-centers.multiple' | translate}}</h1>
    <button
      class="btn btn-outline-secondary"
      (click)="handleAddStudyCenterClick()"
      >
      {{'study-centers.add' | translate}}
    </button>
  </div>
  <ng-container *ngIf="studyCenters$ | async as studyCenters">
    <div class="table-responsive">
      <table class="table table-striped table-hover mt-4">
        <thead>
          <tr>
            <th>{{'entity.id' | translate}}</th>
            <th>{{'study-centers.name' | translate}}</th>
            <th>{{'study-centers.description' | translate}}</th>
            <th class="text-center">{{'application.action' | translate}}</th>
          </tr>
        </thead>
        <tbody>
            <tr *ngFor="let center of studyCenters">
              <td>{{center.id}}</td>
              <td><p class="shorten-long-text w15" tooltip="{{center.name}}" [delay]="500">{{center.name}}</p></td>
              <td><p class="shorten-long-text w30" tooltip="{{center?.description}}" [delay]="500">{{center?.description}}</p></td>
              <td class="text-center">
                <span class="material-icons hover-pointer" (click)="handleEditStudyCenterClick(center)">create</span>
              </td>
            </tr>
        </tbody>
      </table>
    </div>
  </ng-container>
</div>

<app-async-state-overlay [processKeys]="processKeys"></app-async-state-overlay>
