import { ActionCreator, NotAllowedCheck, Props } from '@ngrx/store';
import { FunctionWithParametersType } from '@ngrx/store/src/models';
import { ApiResponse } from 'projects/shared/src/lib/models';
import { AsyncAction, AsyncData } from '../models/async-action.model';
import { AsyncErrorState } from '../reducers/async-state.reducers';

export function createAsyncAction<T extends string, P extends object>(
  type: T,
  asyncData: AsyncData,
  config?: Props<P> & NotAllowedCheck<P>,
): ActionCreator<string, (props?: P & NotAllowedCheck<P>) => P & AsyncAction> {
  const as = config ? config._as : 'empty';

  switch (as) {
    case 'empty':
      return defineType(type, (
        function () {
          return ({ type: type, asyncData: asyncData });
        }
      ));
    case 'props':
      return defineType(type, (
        function (props) {
          return (Object.assign(
            Object.assign({}, props),
            { type: type, asyncData: asyncData },
          ));
        }
      ));
    default:
      throw new Error('Unexpected config.');
  }
}

// tslint:disable-next-line: no-any
function defineType(type: string, creator: FunctionWithParametersType<any, any>) {
  return Object.defineProperty(creator, 'type', {
      value: type,
      writable: false,
  });
}

export function getErrorState(asyncData: AsyncData): AsyncErrorState | undefined {
  if (!asyncData.asyncError && !asyncData.asyncHttpError && !asyncData.asyncErrorMessage && !asyncData.asyncValidationErrors) {
    return undefined;
  }

  const errorResponse: ApiResponse<null> = asyncData.asyncHttpError
    && asyncData.asyncHttpError.error
    && asyncData.asyncHttpError.error ? asyncData.asyncHttpError.error : null;

  /**
   * Es kam vom Server ein Fehler Objekt zurück
   */
  if (asyncData.asyncHttpError && errorResponse && errorResponse.error) {
    const resError = errorResponse.error;
    return {
      httpStatus: asyncData.asyncHttpError.status,
      errorText: resError.message,
      validationErrors: errorResponse.validationErrors,
    };

  /**
   * Es kam vom Server kein dediziertes Fehlerobjekt zurück, aber es
   * können validierungs Fehler vorhanden sein.
   */
  } else if (asyncData.asyncHttpError) {
    return {
      httpStatus: asyncData.asyncHttpError.status,
      errorTitle: asyncData.asyncErrorTitle,
      errorText: asyncData.asyncErrorMessage,
      validationErrors: asyncData.asyncValidationErrors,
    };
  } else {
    return {
      errorTitle: asyncData.asyncErrorTitle,
      errorText: asyncData.asyncErrorMessage,
    };
  }
}
