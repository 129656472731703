import { Action, createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';

import { ValidationError } from 'projects/shared/src/lib/models';
import { ASYNC_STATE_KEY } from '../definitions/async-state.definitions';
import { AsyncErrorState, AsyncProcessState, AsyncState } from '../reducers/async-state.reducers';

const getAsyncRootState = createFeatureSelector<AsyncState>(ASYNC_STATE_KEY);

export const makeGetAsyncProcessState: (key: string) => MemoizedSelector<object, AsyncProcessState> = (key: string) => createSelector(
  getAsyncRootState,
  (asyncStates: AsyncState) => asyncStates[key],
);

// tslint:disable-next-line: max-line-length
export const makeGetAsyncProcessStates: (keys: string[]) => MemoizedSelector<object, AsyncProcessState[]> = (keys: string[]) => createSelector(
  getAsyncRootState,
  (asyncStates: AsyncState) => keys.map((key: string) => asyncStates[key]).filter((state: AsyncProcessState) => Boolean(state)),
);

export const makeGetAsyncArePending: (keys: string[]) => MemoizedSelector<object, boolean> = (keys: string[]) => createSelector(
  makeGetAsyncProcessStates(keys),
  (asyncStates: AsyncProcessState[]) => {
    const pendingState = asyncStates.find((state: AsyncProcessState) => state.pending);

    return Boolean(pendingState);
  },
);

export const makeGetAsyncInitialAction: (key: string) => MemoizedSelector<object, Action | undefined> = (key: string) => createSelector(
  makeGetAsyncProcessState(key),
  (asyncState: AsyncProcessState) => asyncState ? asyncState.initialAction : undefined,
);

// tslint:disable-next-line: max-line-length
export const makeGetAsyncInitialActions: (keys: string[]) => MemoizedSelector<object, Action[] | undefined> = (keys: string[]) => createSelector(
  makeGetAsyncProcessStates(keys),
  (asyncStates: AsyncProcessState[]) => {
    const initialActions: Action[] | undefined = [];

    asyncStates.forEach((state: AsyncProcessState) => {
      if (state.initialAction) {
        initialActions.push(state.initialAction);
      }
    });

    return initialActions.length > 0 ? initialActions : undefined;
  },
);

export const makeGetAsyncIsRetryable: (key: string) => MemoizedSelector<object, boolean> = (key: string) => createSelector(
  makeGetAsyncInitialAction(key),
  (action: Action | undefined) => Boolean(action),
);

export const makeGetAsyncAreRetryable: (keys: string[]) => MemoizedSelector<object, boolean> = (keys: string[]) => createSelector(
  makeGetAsyncInitialActions(keys),
  (actions: Action[] | undefined) => Boolean(actions),
);

// tslint:disable-next-line: max-line-length
export const makeGetAsyncErrorState: (key: string) => MemoizedSelector<object, AsyncErrorState | undefined> = (key: string) => createSelector(
  makeGetAsyncProcessState(key),
  (asyncState: AsyncProcessState) => asyncState ? asyncState.error : undefined,
);

// tslint:disable-next-line: max-line-length
export const makeGetAsyncErrorStateForKeys: (keys: string[]) => MemoizedSelector<object, AsyncErrorState[] | undefined> = (keys: string[]) => createSelector(
  makeGetAsyncProcessStates(keys),
  (asyncStates: AsyncProcessState[]) => {
    const errorStates: AsyncErrorState[] = [];

    asyncStates.forEach((state: AsyncProcessState) => {
      if (state.error) {
        errorStates.push(state.error);
      }
    });

    return errorStates.length > 0 ? errorStates : undefined;
  },
);

// tslint:disable-next-line: max-line-length
export const makeGetAsyncValidationErrors: (key: string) => MemoizedSelector<object, ValidationError[] | undefined> = (key: string) => createSelector(
  makeGetAsyncErrorState(key),
  (errorState: AsyncErrorState | undefined) => errorState ? errorState.validationErrors : undefined,
);

export const makeGetAsyncIsFailed: (key: string) => MemoizedSelector<object, boolean> = (key: string) => createSelector(
  makeGetAsyncErrorState(key),
  (errorState: AsyncErrorState | undefined) => Boolean(errorState),
);

export const makeGetAsyncAreFailed: (keys: string[]) => MemoizedSelector<object, boolean> = (keys: string[]) => createSelector(
  makeGetAsyncProcessStates(keys),
  (asyncStates: AsyncProcessState[]) => {
    const errorStates = asyncStates.filter((state: AsyncProcessState) => state.error);

    return errorStates.length > 0;
  },
);

export const makeGetAsyncFailedCode: (key: string) => MemoizedSelector<object, number | undefined> = (key: string) => createSelector(
  makeGetAsyncErrorState(key),
  (errorState: AsyncErrorState | undefined) => errorState ? errorState.httpStatus : undefined,
);
